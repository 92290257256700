// ClickOutside.js
import { ref, onMounted, onUnmounted } from 'vue';

export function useClickOutside(elementRef, callback) {
  const isClickOutside = ref(false);

  const handleClick = (event) => {
    if (elementRef.value && !elementRef.value.contains(event.target)) {
      isClickOutside.value = true;
      callback();
    } else {
      isClickOutside.value = false;
    }
  };

  onMounted(() => {
    document.addEventListener('click', handleClick);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleClick);
  });

  return {
    isClickOutside,
  };
}
